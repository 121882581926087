<template>
<div>
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Sınıf/Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple v-model="filterRequest.childClassId" :options="childClass" class="w-100" :reduce="val => val.class.id" :getOptionLabel="option => option.class.name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Alt Ders</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Konu</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Video Grup</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Test</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTest" :options="tests" class="w-100" :reduce="val => val.id" label="title" />
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <label>Durum</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="filterRequest.status" :options="status" class="w-100" :reduce="val => val.key" />
                </b-col>

                <b-col cols="12" md="1" class="mb-md-0 mb-2">
                    <b-button variant="success" @click="getTestData()" class="mt-2">FİLTRELE</b-button>
                </b-col>
                <b-col cols="12" md="1" class="mb-md-0 mb-2">
                    <b-button variant="primary" @click="exportData()" class="mt-2">İNDİR</b-button>
                </b-col>
            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>

    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Sınıf Ortalaması
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Doğru Sayısı:
                        <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{ort.correct}} / {{ort.totalOrt}} </span>
                    </div>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Yanlış Sayısı:
                        <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{ort.wrong}} / {{ort.totalOrt}} </span>
                    </div>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Boş Sayısı:
                        <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{ort.empty}} / {{ort.totalOrt}} </span>
                    </div>
                </b-col>

                <b-col cols="12" md="3" class="mb-md-0 mb-2">
                    <div class="font-weight-bolder">Ortalama Puan:
                        <span style="font-weight: bold;">{{ort.point}} / 100</span>
                    </div>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>

        <b-table :items="users" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <template #cell(status)="data">
                <b-badge pill :variant="getExamStatus(data.item.status)">
                    {{ getExamStatusText(data.item.status) }}
                </b-badge>
            </template>

            <template #cell(action)="data">
                <b-button block variant="flat-danger" class="font-weight-bolder mb-12" @click="removeData(data.item)">Testi Sıfırla</b-button>
                <b-button block variant="flat-success" class="font-weight-bolder mb-12" @click="getTestPreview(data.item)">Testi Gör</b-button>
            </template>

            <!-- Id -->
            <template #cell(username)="data">
                <span class="font-weight-bolder mb-12">{{
                    (data.item.user!=null)?
                    data.item.user.firstName+' '+data.item.user.lastName:
                    "Kullanıcı Bulunamadı"
                    }}</span>
            </template>

        </b-table>
    </b-card>

    <b-modal size="xl" title="Test Detayları" centered cancel-variant="outline-secondary" no-close-on-backdrop v-model="openDetailsModal" hide-footer>
        <b-card no-body>
            <b-button variant="success" @click="getExamResult()" block>İNDİR</b-button>
            <b-card-body>
                <b-row>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Doğru Sayısı:
                            <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{testPreviewData.correct}}</span>
                        </div>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Yanlış Sayısı:
                            <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{testPreviewData.wrong}}</span>
                        </div>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Boş Sayısı:
                            <span class="font-weight-bolder mr-1" style="font-weight: bold;">{{testPreviewData.empty}}</span>
                        </div>
                    </b-col>

                    <b-col cols="12" md="3" class="mb-md-0 mb-2">
                        <div class="font-weight-bolder">Puan:
                            <span style="font-weight: bold;">{{testPreviewData.point}}</span>
                        </div>
                    </b-col>

                </b-row>
            </b-card-body>

            <b-table :items="testPreviewData.questions" responsive :fields="testQuestionFields" class="mb-0">
                <!-- Id -->
                <template #cell(id)="data">
                    <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
                </template>

                <!-- Id -->
                <template #cell(studentAnswer)="data">
                    <span class="font-weight-bolder mb-12">{{
             (data.item.answers.filter(x=>x.isSelected==true).length>0)?data.item.answers.filter(x=>x.isSelected==true)[0].text:"Boş Bıraktı"
              }}</span>
                </template>
                <template #cell(trueAnswer)="data">
                    <span class="font-weight-bolder mb-12">{{
                    data.item.answers.filter(x=>x.isTrue==true)[0].text
              }}</span>
                </template>
            </b-table>

        </b-card>
    </b-modal>

</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import XLSX from 'xlsx';
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress,
        BOverlay
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'username', label: 'Öğrenci Adı Soyadı' },
                { key: 'class', label: 'Şube' },
                { key: 'title', label: 'Test' },
                { key: 'startDate', label: 'Başlama Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'status', label: 'Durum' },
                { key: 'correct', label: 'Doğru' },
                { key: 'wrong', label: 'Yanlış' },
                { key: 'empty', label: 'Boş' },
                { key: 'point', label: 'Puan' },
                { key: 'action', label: 'İşlem' },
            ],
            testQuestionFields: [
                { key: 'id', label: 'Ref' },
                { key: 'gains', label: 'Kazanım' },
                { key: 'studentAnswer', label: 'Öğrencinin Cevabı' },
                { key: 'trueAnswer', label: 'Doğru Cevap' }
            ],
            status: [
                { key: 2, label: 'Devam Ediyor' },
                { key: 4, label: 'Sonuçlar İşleniyor' },
                { key: 5, label: 'Sonuçlandı' },
            ],
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            users: [],
            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            filterText: '',
            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 5
            },
            ort: {
                totalOrt: 0,
                correct: 0,
                wrong: 0,
                empty: 0,
                point: 0
            },
            openDetailsModal: false,
            testPreviewData: {}
        }
    },
    created() {
        this.getLesson();
    },
    watch: {
        selectedLesson: function (val) {
            this.getChildLesson();
            this.childClass = [];
            this.filterRequest.childClassId = null;
            if (val != null) {
                var filterData = this.lessons.filter(x => x.id == val)[0];
                this.childClass = filterData.childClass;
            }
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getTests();
        }
    },
    methods: {

        removeData(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.loading = true;
                        this.$http.delete("Report/UserTest/" + data.testId + "/" + data.user.id).then(() => {
                                this.loading = false;
                                this.getTestData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Öğrenci Testi Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                this.loading = false;
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        },
        async getExamResult() {
            let excelItems = [];
            var index = 1;
            this.testPreviewData.questions.forEach(x => {
                //console.log(x);
                var studentAnswer = x.answers.filter((x) => x.isSelected == true);
              
                studentAnswer= (studentAnswer.length>0)? studentAnswer[0]:{"text":"Boş Bıraktı"};
                excelItems.push({
                    "Soru Numarası": index,
                    "Kazanım": x.gains,
                    "Öğrencinin Cevabı": studentAnswer.text,
                    "Doğru Cevap": x.answers.filter((x) => x.isTrue == true)[0].text
                });
                index++;
            })
            let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, this.testPreviewData.title + ".xlsx");
        },
        async getTestPreview(data) {
            var testDettails = await this.$http.get(`Report/UserTestPreview/${data.testId}/${data.user.id}`);
            this.testPreviewData = testDettails.data.data;
            this.openDetailsModal = true;
        },
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },

        async getTests() {
            var tests = await this.$http.get("Teacher/Test/" + this.selectedVideoGroup);
            this.tests = tests.data.data;
        },

        async getTestData() {
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                this.loading = true;
                data = data[0];

                var request = {
                    lessonId: data.lesson.id,
                    classId: "00000000-0000-0000-0000-000000000000",
                    childLessonId: "00000000-0000-0000-0000-000000000000",
                    topicId: "00000000-0000-0000-0000-000000000000",
                    videoGroupId: "00000000-0000-0000-0000-000000000000",
                    testId: "00000000-0000-0000-0000-000000000000",
                    status: null
                };

                if (this.filterRequest.childClassId != null) { request.Class = this.filterRequest.childClassId; }
                if (this.selectedChildLesson != null) { request.childLessonId = this.selectedChildLesson; }
                if (this.selectedTopic != null) { request.topicId = this.selectedTopic; }
                if (this.selectedVideoGroup != null) { request.videoGroupId = this.selectedVideoGroup; }
                if (this.selectedTest != null) { request.testId = this.selectedTest; }
                if (this.filterRequest.status != null) { request.status = this.filterRequest.status; }

                var lesson = await this.$http.post("Report/Test", request);
                this.users = lesson.data.data;
                this.getCalculator();
                this.loading = false;
            }
        },
        async exportData() {
            let excelItems = [];
            this.users.forEach(x => {
                //console.log(x);
                excelItems.push({
                    "Öğrenci Ad Soyad": x.user.firstName+' '+x.user.lastName,
                    "Şube": x.class,
                    "Test": x.title,
                    "Doğru": x.correct,
                    "Yanlış": x.wrong,
                    "Boş": x.empty,
                    "Puan":x.point
                });
            })
            let data = XLSX.utils.json_to_sheet(excelItems);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, "TestReport.xlsx");
        },
        getQuestionCount() {
            var testQuestion = [];
            this.users.forEach(element => {
                var testtt = testQuestion.filter(x => x.id == element.testId);
                if (testtt == null || testtt.length == 0) {
                    testQuestion.push({
                        id: element.testId,
                        count: element.correct + element.wrong + element.empty,
                        title: element.title
                    });
                }
            });
            return testQuestion;
        },

        getCalculator() {

            var correctOrt = 0;
            var emptyOrt = 0;
            var wrongOrt = 0;
            var pointOrt = 0;
            var totalOrt = 0;
            var qstr = this.getQuestionCount();

            this.users.forEach(element => {
                correctOrt += element.correct;
                emptyOrt += element.empty;
                wrongOrt += element.wrong;
                pointOrt += element.point;
                totalOrt += qstr.filter(x => x.id == element.testId)[0].count
            });

            this.ort.totalOrt = (totalOrt / this.users.length).toFixed(2);
            this.ort.correct = (correctOrt / this.users.length).toFixed(2);
            this.ort.empty = (emptyOrt / this.users.length).toFixed(2);
            this.ort.wrong = (wrongOrt / this.users.length).toFixed(2);
            this.ort.point = (pointOrt / this.users.length).toFixed(2);

        },

        getExamStatus(status) {
            switch (status) {
                case 'Open':
                    return 'primary'
                case 'Waiting':
                    return 'info'
                case 'Closed':
                    return 'secondary'
                case 'Processing':
                    return 'warning'
                case 'HasResulted':
                    return 'success'
            }
        },

        getExamStatusText(status) {
            switch (status) {
                case 'Open':
                    return "Başlamadı"
                case 'Waiting':
                    return "Devam Ediyor"
                case 'Closed':
                    return "Kapalı"
                case 'Processing':
                    return "Sonuçlar İşleniyor"
                case 'HasResulted':
                    return "Sonuçlandı"
            }
        }

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
